import React from 'react';
import SEO from '../components/SEO';

export default function FourOhFour() {
  return (
    <>
      <SEO title="Page Not Found" />
      <p>Hey! That page doesn't exist!</p>
    </>
  );
}
